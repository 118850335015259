function stringIsValidJson(value) {
  try {
    JSON.parse(value)
    return true
  } catch (e) {
    return false
  }
}

export default (code) => {
  if (typeof code == 'string') {
    if (stringIsValidJson(code)) {
      return JSON.stringify(JSON.parse(code), null, 4)
    } else {
      // malformed json detected, return raw string
      return code
    }
  } if (code === null || code === undefined) {
    return ''
  } else {
    return JSON.stringify(code, null, 4)
  }
}
