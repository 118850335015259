<template>
  <editor
    v-model="code"
    width="100%"
    :height="height"
    @init="editorInit"
    lang="json"
    theme="chrome"
    v-on="{ ...$listeners, input: handleValueChanges }"
    v-bind="$attrs"
  >
  </editor>
</template>

<script>
import isValidJsonArray from '@/utils/isValidJsonArray'
import prettyPrintCode from '@/utils/prettyPrintCode'
import objectIsEqual from 'lodash/isEqual'
import editor from 'vue2-ace-editor'

function stringIsValidJson(value) {
  try {
    JSON.parse(value)
    return true
  } catch (e) {
    return false
  }
}

export default {
  components: { editor },
  props: {
    value: {
      type: [Array, Object, String, null, undefined],
      required: false,
    },
    height: {
      type: String,
      default: '200px',
    },
  },
  data: () => ({
    code: '',
  }),
  watch: {
    value () {
      this.handleValueChangesFromProperty()
    },
  },
  mounted () {
    this.code = prettyPrintCode(this.value)
  },
  methods: {
    editorInit () {
      require('brace/ext/language_tools') //language extension prerequsite...
      require('brace/mode/json')
      require('brace/theme/chrome')
    },
    handleValueChangesFromProperty() {
      const passedInValueHasChanged = false === (this.code.length > 0 && objectIsEqual(JSON.parse(this.code), this.value))

      if (passedInValueHasChanged) {
        // NOTE: only perform pretty print when value is sent from parent
        this.code = prettyPrintCode(this.value)
      } else {
        // NOTE: do not pretty print when code editor content is changed
      }
    },
    handleValueChanges() {
      if (stringIsValidJson(this.code)) {
        this.$emit('input', JSON.parse(this.code))
      }
    },
    // APIs for parent component to call
    isValidJson () {
      return stringIsValidJson(this.code)
    },
    isValidArray () {
      return isValidJsonArray(this.code)
    },
  },
}
</script>
